import React from 'react';
import HomeTemplate from '../../templates/HomeTemplate';

const Page: React.SFC = (props) => (
  <HomeTemplate
    {...props}
    header="The best knowledge base for teams"
    subheader="Easy and powerful knowledge base software designed for everyone on your team, from the non-technical to the tech-savvy."
    metaTitle="The best knowledge base for teams - Slab"
    metaDescription="Easy and powerful knowledge base software designed for everyone on your team, from the non-technical to the tech-savvy."
    hideNavbarLinks
  />
);

export default Page;
